import { tv } from '@29cm/configs/tailwind-variants';
import { IconButton } from '@29cm/ruler';
import { memo, useCallback } from 'react';
import { LikeCountButton } from '../LikeCountButton';
import { ProductHorizontalCardPostfixType } from './ProductHorizontalCard.types';

const postfixStyles = tv({
  variants: {
    type: {
      likeCountButton: 'm-auto',
      iconButton: 'h-[44px] min-h-[44px] w-[44px] min-w-[44px]',
    },
  },
  defaultVariants: {
    type: 'likeCountButton',
  },
});

interface Props {
  postfix: ProductHorizontalCardPostfixType;
}

export const ProductHorizontalCardPostfix = memo(({ postfix }: Props) => {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault(); // 기본 동작 방지 (e.g. 부모 요소 링크 이동)
      e.stopPropagation();
      postfix.onClick?.();
    },
    [postfix],
  );

  if (postfix.type === 'likeCountButton') {
    return (
      <LikeCountButton
        className={postfixStyles({ type: postfix.type })}
        priority="default"
        on={postfix.onHeart}
        onClick={handleClick}
        count={postfix.likeAmount}
      />
    );
  }

  return (
    <div className="flex items-center">
      <IconButton
        className={postfixStyles({ type: postfix.type })}
        icon={postfix.icon}
        priority="secondary"
        size="medium"
        onClick={handleClick}
        quiet
      />
    </div>
  );
});
