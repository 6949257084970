import { FetcherKey } from '@29cm/contexts-common-constants';
import { useCachedFetch } from '@29cm/contexts-common-hooks';
import { useCacheStore } from '@29cm/contexts-common-hooks/src/cache';
import { fetchHeartListByType, fetchSetHeart, fetchUnsetHeart } from '@29cm/contexts-hearts-services';
import { getResultData } from '@29cm/contexts-http/utils';
import { useEffect, useMemo, useState } from 'react';

const CACHE_TTL = 1000 * 60 * 5;

export const useProductHeart = (productId: number, likeAmount: number, onChange?: (isHeartOn: boolean) => void) => {
  const { result } = useCachedFetch(fetchHeartListByType, { type: 'product' });

  const heartList = useMemo(() => getResultData(result, []), [result]);

  const [isHeartOn, setIsHeartOn] = useState(false);
  const [heartCount, setHeartCount] = useState(likeAmount);

  useEffect(() => {
    if (heartList.length === 0) {
      return;
    }
    setIsHeartOn(heartList.includes(productId));
  }, [heartList, productId, result]);

  const mutateHeartState = isHeartOn ? fetchUnsetHeart : fetchSetHeart;

  const [, set] = useCacheStore(JSON.stringify([FetcherKey.FetchUserHeartList, { type: 'product' }]), {
    ttl: CACHE_TTL,
    initialValue: reshapeCache(getResultData(result, [])),
  });

  const toggleHeart = () => {
    mutateHeartState({ type: 'product', target: productId }).then(() => {
      const nextList = isHeartOn ? heartList.filter((id) => id !== productId) : [...heartList, productId];

      set(reshapeCache(nextList));
      setHeartCount((prev) => (isHeartOn ? prev - 1 : prev + 1));
      onChange?.(!isHeartOn);
    });
  };

  return {
    isHeartOn,
    heartCount,
    toggleHeart,
  };
};

export const reshapeCache = <T>(data: T) => ({
  status: 'done',
  result: {
    success: true,
    data,
  },
});
