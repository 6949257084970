import { cn } from '@29cm/configs/tailwind-variants';
import { CSSProperties, ReactNode } from 'react';

interface Props<T> {
  className?: string;
  item: T;
  index: number;
  snapAlign: CSSProperties['scrollSnapAlign'];
  snapStop?: CSSProperties['scrollSnapStop'];
  itemWidth: {
    sm: CSSProperties['width'];
    md?: CSSProperties['width'];
  };
  focused: boolean;
  renderItem: (item: T, index: number) => ReactNode;
}

export const CarouselItem = <T extends { id: string | number }>({
  className,
  item,
  index,
  snapAlign,
  snapStop,
  itemWidth,
  focused,
  renderItem,
}: Props<T>) => {
  return (
    <div
      data-focused={focused}
      className={cn('h-full w-[--width-sm] flex-none min-[541px]:w-[--width-md]', className)}
      style={{
        scrollSnapAlign: snapAlign,
        scrollSnapStop: snapStop,
        ...{
          '--width-sm': itemWidth.sm,
          '--width-md': itemWidth.md ?? itemWidth.sm,
        },
      }}
    >
      {renderItem(item, index)}
    </div>
  );
};
