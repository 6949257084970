'use client';

import { transparentFallbackImageSrc } from '@29cm/contexts-common-constants';
import { getCampaignLink, getImageUrl } from '@29cm/contexts-common-utils';
import { Link } from '@29cm/contexts-common-views';
import { SCREEN } from '@29cm/contexts-events/constants';
import {
  trackClickFeedProductEvent,
  trackImpressionFeedProductEvent,
  trackLikeProductEvent,
  trackUnlikeProductEvent,
} from '@29cm/contexts-events/services';
import { Feed, FeedProduct } from '@29cm/contexts-feeds-services';
import { ProductHorizontalCardWithHeart } from '@29cm/contexts-products/features';
import { Screen } from '@29cm/tracker';

interface Props {
  product: FeedProduct;
  feed: Feed;
  position: number;
  feedPosition: number;
}

export const ContentsFeedItem = ({ product, feed, position, feedPosition }: Props) => {
  const { images, frontBrand, name, sale, heartCount, id } = product;
  const [image] = images;

  const imageUrl = getImageUrl(image?.uploadName ?? transparentFallbackImageSrc);
  const href = getCampaignLink('product', id);

  const handleClick = () => {
    trackClickFeedProductEvent({ feed, product, position, feedPosition });
  };

  const handleHeartChange = (isHeartOn: boolean) => {
    console.log(product);
    if (isHeartOn) {
      trackLikeProductEvent({ product, screen: SCREEN.HOME_MAIN });
    } else {
      trackUnlikeProductEvent({ product, screen: SCREEN.HOME_MAIN });
    }
  };

  const handleImpressed = () => trackImpressionFeedProductEvent({ feed, product, position, feedPosition });

  return (
    <li className="border-t border-solid border-line pt-10">
      <Screen.Target onImpressed={handleImpressed}>
        <Link href={href} onClick={handleClick}>
          <ProductHorizontalCardWithHeart
            productId={id}
            imgUrl={imageUrl}
            productName={name}
            price={sale.totalSellPrice}
            discount={sale.totalSaleRate}
            brandName={frontBrand.nameKor}
            likeAmount={heartCount}
            onHeartChange={handleHeartChange}
          />
        </Link>
      </Screen.Target>
    </li>
  );
};
