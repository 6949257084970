import { tv } from '@29cm/configs/tailwind-variants';
import { forwardRef, memo } from 'react';
import { BadgeProps } from './Badge.types';

export const badge = tv({
  base: 'inline-block align-baseline',
  variants: {
    size: {
      small: 'min-w-[16px] rounded-2 px-6 py-2 text-xxs-medium',
      medium: 'min-w-[24px] rounded-2 px-8 py-4 text-s-medium',
    },
    priority: {
      primary: 'bg-primary text-on-color',
      secondary: 'bg-tertiary text-secondary',
      tertiary: 'bg-tertiary text-tertiary',
      information: 'bg-interactive-low text-interactive',
    },
    isBlur: {
      true: 'bg-blur-ultrathin text-on-color',
    },
  },
});

/**
 * TODO: Prefix Icon이 추가되는 작업이 필요합니다.
 * @see {@link https://www.figma.com/design/s9302fXx9ryIoqnx4e6qOU/RulerComponent?m=auto&node-id=5677-11818}
 * 사전에 Icon 컴포넌트에 대한 작업이 완료되어야 합니다.
 * 현재 Ruler repository에 Icon 컴포넌트 디펜던시로 인해 작업이 어려우므로, Icon 컴포넌트 마이그레이션 작업 후 진행 예정입니다.
 */

const BaseBadge = forwardRef<HTMLDivElement, BadgeProps>(
  ({ children, className, size = 'small', isBlur, priority = 'primary', ...props }, ref) => {
    const style = badge({ size, priority, isBlur, className });

    return (
      <div className={style} ref={ref} {...props}>
        {children}
      </div>
    );
  },
);

export const Badge = memo(BaseBadge);
