export const APP_INSTALL_LAYER_ONE_TIME_DATA = {
  title: '9월 29일 단 하루만!\n29DAY 29% 쿠폰을 받아보세요',
  imageUrl: 'https://img.29cm.co.kr/next-edition/2024/08/27/50d7c1d43c7546c880b79638e16331f8_20240827220227.png',
  buttonText: '가입하고 29DAY 쿠폰받기',
};

export const APP_INSTALL_LAYER_DATA = {
  title: '지금 앱을 설치하면\n2장의 쿠폰을 선물하고 있어요',
  imageUrl: 'https://img.29cm.co.kr/next-edition/2023/06/01/1dc47432ac5a4f16952e1996dd37a925_20230601202920.png',
  buttonText: '첫구매용 앱 쿠폰받기',
};
