import { firebase, snowplow } from '@29cm/contexts-analytics/services';
import { Feed, FeedProduct, FeedVariant } from '@29cm/contexts-feeds-services';
import { SCREEN, WIDGET } from '../constants';
import { createEvent } from './createEvent';

// TODO: 스키마 기반의 이벤트 타입 정의
const createFeedLegacyProperties = ({ feed }: { feed: Feed }) => ({
  feed_no: `${feed.id}`,
  feed_type: feed.type,
  feed_title: feed.title,
  feed_contents: feed.contents,
  link_type: feed.linkType,
  link_value: feed.linkValue,
  // NOTE: As-is 모바일 디바이스에서 position 을 항상 right 로 수집 (모바일은 list 가 세로 한 줄)
  position: 'right',
});

// TODO: 스키마 기반의 이벤트 타입 정의
const createProductLegacyProperties = (product: FeedProduct) => ({
  item_no: `${product.id}`,
  item_name: product.name,
  like_count: `${product.heartCount}`,
  price: `${product.sale.sellPrice}`,
  review_count: `${product.reviewCount}`,
});

// TODO: 스키마 기반의 이벤트 타입 정의
const createFeedProperties = ({ feed, position }: { feed: Feed; position: number }) => ({
  current_screen: SCREEN.HOME_MAIN,
  feed_no: feed.id,
  feed_type: feed.type,
  feed_title: feed.title,
  link_type: feed.linkType,
  link_value: feed.linkValue,
  position,
  home_tab: WIDGET.HOME_TAB,
});

// TODO: 스키마 기반의 이벤트 타입 정의
const createFeedProductProperties = ({
  feed,
  product,
  position,
  feedPosition,
}: {
  feed: Feed;
  product: FeedProduct;
  position: number;
  feedPosition: number;
}) => ({
  current_screen: SCREEN.HOME_MAIN,
  item_no: product.id,
  item_name: product.name,
  brand_no: product.frontBrand.id,
  brand_name: product.frontBrand.nameKor,
  source: feed.type === FeedVariant.PRODUCT ? 'carousel' : feed.type,
  price: product.sale.sellPrice,
  feed_no: feed.id,
  home_tab: WIDGET.HOME_TAB,
  position,
  feed_position: feedPosition,
});

export const trackClickFeedEvent = createEvent<{ feed: Feed; position: number }>({
  name: 'click_feed',
  trackers: [
    firebase.track({ properties: createFeedLegacyProperties }),
    snowplow.track({
      name: 'iglu:com.29cm/click_feed/jsonschema/2-0-0',
      properties: createFeedProperties,
    }),
  ],
});

export const trackClickFeedProductEvent = createEvent<{
  feed: Feed;
  product: FeedProduct;
  position: number;
  feedPosition: number;
}>({
  name: 'click_feed',
  trackers: [
    firebase.track({
      properties: ({ product, ...rest }) => ({
        ...createFeedLegacyProperties(rest),
        ...createProductLegacyProperties(product),
      }),
    }),
    snowplow.track({
      name: 'iglu:com.29cm/click_item_feed/jsonschema/1-0-0',
      properties: createFeedProductProperties,
    }),
  ],
});

export const trackViewFeedEvent = createEvent<{ feed: Feed; position: number }>({
  name: 'view_feed',
  trackers: [
    snowplow.track({
      name: 'iglu:com.29cm/view_feed/jsonschema/2-0-0',
      properties: ({ feed, position }) => ({
        ...createFeedProperties({ feed, position }),
        // NOTE: (한지원) view_feed 이벤트 수집 조건 1px 노출
        threshold: 1,
      }),
    }),
  ],
});

export const trackImpressionFeedProductEvent = createEvent<{
  feed: Feed;
  product: FeedProduct;
  position: number;
  feedPosition: number;
}>({
  name: 'impression_item_feed',
  trackers: [
    snowplow.track({
      name: 'iglu:com.29cm/impression_item_feed/jsonschema/1-0-0',
      properties: createFeedProductProperties,
    }),
  ],
});
