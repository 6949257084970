import { firebase, snowplow } from '@29cm/contexts-analytics/services';
import { Banner } from '@29cm/contexts-banners-services';
import { Feed } from '@29cm/contexts-feeds-services';
import { SCREEN, WIDGET } from '../constants';
import { createEvent } from './createEvent';

// TODO: 스키마 기반의 이벤트 타입 정의
const createBannerFeedLegacyProperties = (feed: Feed) => ({
  banner_no: `${feed.id}`,
  banner_title: feed.title,
  link_type: feed.linkType,
  link_value: feed.linkValue,
  position: feed.orderingIndex % 2 === 0 ? 'left' : 'right',
});

// TODO: 스키마 기반의 이벤트 타입 정의
const createBannerLegacyProperties = ({ banner, position }: { banner: Banner; position: number }) => ({
  banner_no: `${banner.bannerId}`,
  banner_title: banner.bannerTitle,
  banner_contents: banner.bannerContents,
  link_type: banner.linkType,
  link_value: banner.linkValue,
  position: `${position}`,
});

// TODO: 스키마 기반의 이벤트 타입 정의
const createBannerProperties = ({ banner, position }: { banner: Banner; position: number }) => ({
  current_screen: SCREEN.HOME_MAIN,
  banner_no: banner.bannerId,
  banner_title: banner.bannerTitle,
  banner_type: 'home_banner',
  link_type: banner.linkType,
  link_value: banner.linkValue,
  position,
  home_tab: WIDGET.HOME_TAB,
});

export const trackClickSideHomeBannerEvent = createEvent<Feed>({
  name: 'click_side_home_banner',
  trackers: [firebase.track({ properties: createBannerFeedLegacyProperties })],
});

export const trackViewSideHomeBannerEvent = createEvent<Feed>({
  name: 'view_side_home_banner',
  trackers: [firebase.track({ properties: createBannerFeedLegacyProperties })],
});

export const trackClickHomeBannerEvent = createEvent<{ banner: Banner; position: number }>({
  name: 'click_home_banner',
  trackers: [
    firebase.track({ properties: createBannerLegacyProperties }),
    snowplow.track({
      name: 'iglu:com.29cm/click_banner/jsonschema/1-0-1',
      properties: createBannerProperties,
    }),
  ],
});

export const trackViewHomeBannerEvent = createEvent<{ banner: Banner; position: number }>({
  name: 'view_home_banner',
  trackers: [
    firebase.track({ properties: createBannerLegacyProperties }),
    snowplow.track({
      name: 'iglu:com.29cm/view_banner/jsonschema/1-0-0',
      properties: ({ banner, position }) => ({
        ...createBannerProperties({ banner, position }),
        // NOTE: (한지원) view_feed 이벤트 수집 조건 50% 노출
        threshold: 50,
      }),
    }),
  ],
});
