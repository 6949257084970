import type { Config } from 'tailwindcss';
import defaultTheme from 'tailwindcss/defaultTheme';

const fontSize = {
  'font-size-25': '10px',
  'font-size-50': '11px',
  'font-size-75': '12px',
  'font-size-100': '13px',
  'font-size-125': '14px',
  'font-size-150': '15px',
  'font-size-200': '16px',
  'font-size-250': '18px',
  'font-size-300': '20px',
  'font-size-350': '22px',
  'font-size-400': '24px',
  'font-size-500': '28px',
  'font-size-550': '30px',
  'font-size-600': '32px',
  'font-size-1000': '48px',
};

const fontWeight = {
  'thin-100': '100',
  'extraLight-200': '200',
  'light-300': '300',
  'regular-400': '400',
  'medium-500': '500',
  'semibold-600': '600',
  'bold-700': '700',
  'extraBold-800': '800',
};

const lineHeightToken = {
  'line-height-1': '100%',
  'line-height-2': '120%',
  'line-height-3': '136%',
  'line-height-4': '140%',
  'line-height-5': '150%',
  'line-height-6': '160%',
};

export const fontTheme = {
  fontSize: {
    'title-xxl': [
      fontSize['font-size-550'],
      {
        lineHeight: lineHeightToken['line-height-3'],
        fontWeight: fontWeight['regular-400'],
      },
    ],
    'title-xxl-medium': [
      fontSize['font-size-550'],
      {
        lineHeight: lineHeightToken['line-height-3'],
        fontWeight: fontWeight['medium-500'],
      },
    ],
    'title-xxl-bold': [
      fontSize['font-size-550'],
      {
        lineHeight: lineHeightToken['line-height-3'],
        fontWeight: fontWeight['bold-700'],
      },
    ],
    'title-xl': [
      fontSize['font-size-500'],
      {
        lineHeight: lineHeightToken['line-height-3'],
        fontWeight: fontWeight['regular-400'],
      },
    ],
    'title-xl-medium': [
      fontSize['font-size-500'],
      {
        lineHeight: lineHeightToken['line-height-3'],
        fontWeight: fontWeight['medium-500'],
      },
    ],
    'title-xl-bold': [
      fontSize['font-size-500'],
      {
        lineHeight: lineHeightToken['line-height-3'],
        fontWeight: fontWeight['bold-700'],
      },
    ],
    'title-l': [
      fontSize['font-size-400'],
      {
        lineHeight: lineHeightToken['line-height-3'],
        fontWeight: fontWeight['regular-400'],
      },
    ],
    'title-l-medium': [
      fontSize['font-size-400'],
      {
        lineHeight: lineHeightToken['line-height-3'],
        fontWeight: fontWeight['medium-500'],
      },
    ],
    'title-l-bold': [
      fontSize['font-size-400'],
      {
        lineHeight: lineHeightToken['line-height-3'],
        fontWeight: fontWeight['bold-700'],
      },
    ],
    'title-m': [
      fontSize['font-size-350'],
      {
        lineHeight: lineHeightToken['line-height-3'],
        fontWeight: fontWeight['regular-400'],
      },
    ],
    'title-m-medium': [
      fontSize['font-size-350'],
      {
        lineHeight: lineHeightToken['line-height-3'],
        fontWeight: fontWeight['medium-500'],
      },
    ],
    'title-m-bold': [
      fontSize['font-size-350'],
      {
        lineHeight: lineHeightToken['line-height-3'],
        fontWeight: fontWeight['bold-700'],
      },
    ],
    'title-s': [
      fontSize['font-size-300'],
      {
        lineHeight: lineHeightToken['line-height-3'],
        fontWeight: fontWeight['regular-400'],
      },
    ],
    'title-s-medium': [
      fontSize['font-size-300'],
      {
        lineHeight: lineHeightToken['line-height-3'],
        fontWeight: fontWeight['medium-500'],
      },
    ],
    'title-s-bold': [
      fontSize['font-size-300'],
      {
        lineHeight: lineHeightToken['line-height-3'],
        fontWeight: fontWeight['bold-700'],
      },
    ],
    'title-xs': [
      fontSize['font-size-250'],
      {
        lineHeight: lineHeightToken['line-height-3'],
        fontWeight: fontWeight['regular-400'],
      },
    ],
    'title-xs-medium': [
      fontSize['font-size-250'],
      {
        lineHeight: lineHeightToken['line-height-3'],
        fontWeight: fontWeight['medium-500'],
      },
    ],
    'title-xs-bold': [
      fontSize['font-size-250'],
      {
        lineHeight: lineHeightToken['line-height-3'],
        fontWeight: fontWeight['bold-700'],
      },
    ],
    'xxl': [
      fontSize['font-size-200'],
      {
        lineHeight: lineHeightToken['line-height-4'],
        fontWeight: fontWeight['regular-400'],
      },
    ],
    'xxl-medium': [
      fontSize['font-size-200'],
      {
        lineHeight: lineHeightToken['line-height-4'],
        fontWeight: fontWeight['medium-500'],
      },
    ],
    'xxl-bold': [
      fontSize['font-size-200'],
      {
        lineHeight: lineHeightToken['line-height-4'],
        fontWeight: fontWeight['bold-700'],
      },
    ],
    'xl': [
      fontSize['font-size-150'],
      {
        lineHeight: lineHeightToken['line-height-5'],
        fontWeight: fontWeight['regular-400'],
      },
    ],
    'xl-medium': [
      fontSize['font-size-150'],
      {
        lineHeight: lineHeightToken['line-height-5'],
        fontWeight: fontWeight['medium-500'],
      },
    ],
    'xl-bold': [
      fontSize['font-size-150'],
      {
        lineHeight: lineHeightToken['line-height-5'],
        fontWeight: fontWeight['bold-700'],
      },
    ],
    'l': [
      fontSize['font-size-125'],
      {
        lineHeight: lineHeightToken['line-height-4'],
        fontWeight: fontWeight['regular-400'],
      },
    ],
    'l-medium': [
      fontSize['font-size-125'],
      {
        lineHeight: lineHeightToken['line-height-4'],
        fontWeight: fontWeight['medium-500'],
      },
    ],
    'l-bold': [
      fontSize['font-size-125'],
      {
        lineHeight: lineHeightToken['line-height-4'],
        fontWeight: fontWeight['bold-700'],
      },
    ],
    'm': [
      fontSize['font-size-100'],
      {
        lineHeight: lineHeightToken['line-height-4'],
        fontWeight: fontWeight['regular-400'],
      },
    ],
    'm-medium': [
      fontSize['font-size-100'],
      {
        lineHeight: lineHeightToken['line-height-4'],
        fontWeight: fontWeight['medium-500'],
      },
    ],
    'm-bold': [
      fontSize['font-size-100'],
      {
        lineHeight: lineHeightToken['line-height-4'],
        fontWeight: fontWeight['bold-700'],
      },
    ],
    's': [
      fontSize['font-size-75'],
      {
        lineHeight: lineHeightToken['line-height-3'],
        fontWeight: fontWeight['regular-400'],
      },
    ],
    's-medium': [
      fontSize['font-size-75'],
      {
        lineHeight: lineHeightToken['line-height-3'],
        fontWeight: fontWeight['medium-500'],
      },
    ],
    's-bold': [
      fontSize['font-size-75'],
      {
        lineHeight: lineHeightToken['line-height-3'],
        fontWeight: fontWeight['bold-700'],
      },
    ],
    'xs-medium': [
      fontSize['font-size-50'],
      {
        lineHeight: lineHeightToken['line-height-3'],
        fontWeight: fontWeight['medium-500'],
      },
    ],
    'xs': [
      fontSize['font-size-50'],
      {
        lineHeight: lineHeightToken['line-height-3'],
        fontWeight: fontWeight['regular-400'],
      },
    ],
    'xs-bold': [
      fontSize['font-size-50'],
      {
        lineHeight: lineHeightToken['line-height-3'],
        fontWeight: fontWeight['bold-700'],
      },
    ],
    'xxs': [
      fontSize['font-size-25'],
      {
        lineHeight: lineHeightToken['line-height-2'],
        fontWeight: fontWeight['regular-400'],
      },
    ],
    'xxs-medium': [
      fontSize['font-size-25'],
      {
        lineHeight: lineHeightToken['line-height-2'],
        fontWeight: fontWeight['medium-500'],
      },
    ],
    'xxs-bold': [
      fontSize['font-size-25'],
      {
        lineHeight: lineHeightToken['line-height-2'],
        fontWeight: fontWeight['bold-700'],
      },
    ],
  },
  fontFamily: {
    sans: ['var(--font-pretendard)', ...defaultTheme.fontFamily.sans],
    campton: ['var(--font-campton)', 'var(--font-pretendard)', ...defaultTheme.fontFamily.sans],
  },
} satisfies Config['theme'];
