export const zIndexTheme = {
  popup: '3000',
  mask: '1000',
  navigation: '200',
  ['content-1']: '20',
  ['content-2']: '40',
  ['content-3']: '60',
  ['content-4']: '80',
  ['content-5']: '100',
  ['content-6']: '120',
  ['content-7']: '140',
  ['content-8']: '160',
  ['content-9']: '180',
};
