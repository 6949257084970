'use client';

import { cn } from '@29cm/configs/tailwind-variants';
import { Banners, fetchHomeMobileBannerList } from '@29cm/contexts-banners-services';
import { useFetch } from '@29cm/contexts-common-hooks';
import { mobileBannerSize } from '@29cm/contexts-home/constants';
import { Carousel, CarouselRef } from '@29cm/contexts-home/views';
import { Result } from '@29cm/contexts-http/interfaces';
import { getResultData } from '@29cm/contexts-http/utils';
import { useCallback, useRef, useState } from 'react';
import { BannerCarouselItem } from './BannerCarouselItem';
import { BannerCarouselNavigator } from './BannerCarouselNavigator';
import { BannerCarouselProgress } from './BannerCarouselProgress';

const horizontalPadding = {
  sm: '20px',
};

interface Props {
  className?: string;
  initialBanners?: Result<Banners, Error>;
}

export const BannerCarousel = ({ className, initialBanners }: Props) => {
  const cardCarouselRef = useRef<CarouselRef>(null);
  const [focusedIndex, setFocusedIndex] = useState(0);

  const { result: mobileBannerResult } = useFetch(
    fetchHomeMobileBannerList,
    { size: mobileBannerSize },
    { initialResult: initialBanners },
  );

  const bannerList = (getResultData(mobileBannerResult)?.results ?? []).map((item) => ({ id: item.bannerId, item }));

  const renderItem = useCallback((item: (typeof bannerList)[number], index: number) => {
    return <BannerCarouselItem item={item.item} position={index + 1} />;
  }, []);

  const handleFocusedIndexChange = useCallback((focusedIndex: number) => {
    setFocusedIndex(focusedIndex);
  }, []);

  if (bannerList.length === 0) {
    return null;
  }

  return (
    <div className={cn('relative my-8', className)}>
      <Carousel
        ref={cardCarouselRef}
        items={bannerList}
        horizontalPadding={horizontalPadding}
        gap={6}
        renderItem={renderItem}
        snapStop="always"
        onFocusedIndexChange={handleFocusedIndexChange}
      />

      <BannerCarouselNavigator
        className="absolute left-[44px] top-1/2 -translate-y-1/2 max-[375px]:left-[40px]"
        direction="previous"
        disabled={focusedIndex === 0}
        onClick={() => {
          cardCarouselRef.current?.focusTo(focusedIndex - 1);
        }}
      />
      <BannerCarouselNavigator
        className="absolute right-[44px] top-1/2 -translate-y-1/2 max-[375px]:right-[40px]"
        direction="next"
        disabled={focusedIndex === bannerList.length - 1}
        onClick={() => {
          cardCarouselRef.current?.focusTo(focusedIndex + 1);
        }}
      />

      <BannerCarouselProgress
        className="absolute inset-x-[62px] bottom-[52px] max-[375px]:inset-x-[40px] max-[375px]:bottom-[19px]"
        current={focusedIndex + 1}
        max={bannerList.length}
      />
    </div>
  );
};
