import { snowplow } from '@29cm/contexts-analytics/services';
import { Screen } from '../interfaces';
import { createEvent } from './createEvent';

// FIXME: Product 모델 정의
type Product = {
  id: number;
  name: string;
  frontBrand: {
    id: number;
    nameKor: string;
    nameEng: string;
  };
  sale: {
    sellPrice: number;
    isSoldOut: boolean;
  };
};

const createProductProperties = ({ product, screen }: { product: Product; screen: Screen }) => ({
  current_screen: screen,
  item_no: product.id,
  item_name: product.name,
  brand_no: product.frontBrand.id,
  brand_name: product.frontBrand.nameKor,
  price: product.sale.sellPrice,
  is_soldout: product.sale.isSoldOut,
});

export const trackLikeProductEvent = createEvent<{ product: Product; screen: Screen }>({
  name: 'like_item',
  trackers: [
    snowplow.track({
      name: 'iglu:com.29cm/like_item/jsonschema/1-0-1',
      properties: createProductProperties,
    }),
  ],
});

export const trackUnlikeProductEvent = createEvent<{ product: Product; screen: Screen }>({
  name: 'unlike_item',
  trackers: [
    snowplow.track({
      name: 'iglu:com.29cm/unlike_item/jsonschema/1-0-0',
      properties: createProductProperties,
    }),
  ],
});
