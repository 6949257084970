import { Feed as FeedType, FeedVariant } from '@29cm/contexts-feeds-services';
import { BannerFeed } from '../BannerFeed';
import { ContentsFeed } from '../ContentsFeed';
import { ProductCollectionFeed } from '../ProductCollectionFeed';

const feedByVariant = {
  [FeedVariant.BANNER]: BannerFeed,
  [FeedVariant.CONTENTS]: ContentsFeed,
  [FeedVariant.PRODUCT]: ProductCollectionFeed,
} as const;

interface Props {
  variant: FeedVariant;
  item: FeedType;
  position: number;
}

export const Feed = ({ variant, item, position }: Props) => {
  const FeedComponent = feedByVariant[variant];

  return <FeedComponent item={item} position={position} />;
};
