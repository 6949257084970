'use client';

import { transparentFallbackImageSrc } from '@29cm/contexts-common-constants';
import { Intersection } from '@29cm/contexts-common-features';
import { getCampaignLink } from '@29cm/contexts-common-utils';
import { ResponsiveImage } from '@29cm/contexts-common-views';
import { trackClickFeedEvent, trackViewFeedEvent } from '@29cm/contexts-events/services';
import { Feed } from '@29cm/contexts-feeds-services';
import Link from 'next/link';
import { PropsWithChildren, memo } from 'react';

interface Props {
  className?: string;
  feed: Feed;
  position: number;
}

export const ContentsFeedLayout = memo(({ className, children, feed, position }: PropsWithChildren<Props>) => {
  const { title, contents, linkType, linkValue, relatedFiles } = feed;

  const [thumbnail] = relatedFiles;

  const href = getCampaignLink(linkType, linkValue);

  const src = thumbnail?.uploadName ?? transparentFallbackImageSrc;

  const handleView = () => trackViewFeedEvent({ feed, position });

  const handleClick = () => trackClickFeedEvent({ feed, position });

  return (
    <div className={className}>
      <div className="flex flex-col gap-12">
        <Intersection onView={handleView}>
          <Link href={href} onClick={handleClick}>
            <div className="flex flex-col gap-12">
              <div className="relative aspect-square overflow-hidden rounded-2 bg-low">
                <ResponsiveImage
                  className="object-cover"
                  src={src}
                  alt={title}
                  fill
                  sizes={{ sm: '100vw', md: '50vw' }}
                />
              </div>
              <div className="flex flex-col gap-10">
                <p className="text-title-m-bold">{title}</p>
                <p className="text-xl">{contents}</p>
              </div>
            </div>
          </Link>
        </Intersection>
        {children}
      </div>
    </div>
  );
});
