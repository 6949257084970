import { getUserAgent } from './getUserAgent';

const APP_INFO_KEY = {
  OS: 'os',
  OS_VERSION: 'osVersion',
  DEVICE_MODEL: 'deviceModel',
  SERVICE_NAME: 'serviceName',
  SERVICE_VERSION: 'serviceVersion',
  SERVICE_VERSION_CODE: 'serviceVersionCode',
  BUILD_NUMBER: 'buildNumber',
} as const;

export const getAppInfo = () => {
  const { ua } = getUserAgent();

  const appInfo = (ua.match(/APP29CM([^)]+)\)/g)?.toString() ?? '').replace('APP29CM(', '').replace(')', '').split(';');

  return appInfo.reduce(
    (prev, text) => {
      const [key, value] = text.split('=');
      switch (key) {
        case APP_INFO_KEY.OS:
        case APP_INFO_KEY.OS_VERSION:
        case APP_INFO_KEY.DEVICE_MODEL:
        case APP_INFO_KEY.SERVICE_NAME:
        case APP_INFO_KEY.SERVICE_VERSION:
          return {
            ...prev,
            [key]: value,
          };
        case APP_INFO_KEY.SERVICE_VERSION_CODE: // NOTE: fade-out 예정, buildNumber 사용
        case APP_INFO_KEY.BUILD_NUMBER:
          return {
            ...prev,
            [key]: Number(value),
          };
        default:
          return prev;
      }
    },
    {
      [APP_INFO_KEY.OS]: '',
      [APP_INFO_KEY.OS_VERSION]: '',
      [APP_INFO_KEY.DEVICE_MODEL]: '',
      [APP_INFO_KEY.SERVICE_NAME]: '',
      [APP_INFO_KEY.SERVICE_VERSION]: '',
      [APP_INFO_KEY.SERVICE_VERSION_CODE]: 0, // NOTE: fade-out 예정, buildNumber 사용
      [APP_INFO_KEY.BUILD_NUMBER]: 0,
    },
  );
};
