import type { Config } from 'tailwindcss';
import { colorTheme } from './color';
import { radiusTheme } from './radius';
import { screensTheme } from './screens';
import { spaceTheme } from './space';
import { fontTheme } from './typography';
import { zIndexTheme } from './zIndex';

export const theme: Config['theme'] = {
  ...colorTheme,
  fontSize: fontTheme.fontSize,
  spacing: spaceTheme,
  borderRadius: radiusTheme,
  screens: screensTheme,
  zIndex: zIndexTheme,
  extend: {
    fontFamily: fontTheme.fontFamily,
  },
};
