import { cn } from '@29cm/configs/tailwind-variants';

interface Props {
  className: string;
  current: number;
  max: number;
}

export const BannerCarouselProgress = ({ className, current, max }: Props) => {
  const convertProgressNumbers = (num: number) => num.toString().padStart(2, '0');

  return (
    <div className={cn('flex items-center gap-10', className)}>
      <div className="flex items-center gap-4 tabular-nums">
        <p className="text-xs-bold text-on-color">{convertProgressNumbers(current)}</p>
        <Dot />
        <p className="text-xs-bold text-on-color opacity-60">{convertProgressNumbers(max)}</p>
      </div>
      <div className="relative h-[1px] w-full overflow-hidden">
        <div className="h-full w-full bg-on-white opacity-50"></div>
        <div
          className="duration-250 absolute left-0 top-0 h-full w-full bg-on-white transition ease-in"
          style={{
            transform: `translateX(-${100 - Math.round((current / max) * 100)}%)`,
          }}
        />
      </div>
    </div>
  );
};

const Dot = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="2" height="3" viewBox="0 0 2 3" fill="none">
      <circle opacity="0.6" cx="0.75" cy="1.5" r="0.75" fill="white" />
    </svg>
  );
};
