import { cn, tv } from '@29cm/configs/tailwind-variants';
import { commaizeNumber } from '@29cm/contexts-common-utils';
import { forwardRef, memo } from 'react';
import { Badge } from '../Badge';
import { ProductHorizontalCardProps } from './ProductHorizontalCard.types';
import { ProductHorizontalCardPostfix } from './ProductHorizontalCardPostfix';

export const productHorizontalCardInfo = tv({
  slots: {
    discount: 'text-accent',
    priceInfo: 'text-primary',
  },
  variants: {
    soldOut: {
      true: {
        discount: 'text-disabled',
        priceInfo: 'text-disabled',
      },
    },
  },
});

// NOTE: 반응형 이미지 최적화를 위한 이미지 너비값. 말 그대로 최소 너비이므로, 화면 확대 등의 이유로 최소 너비보다 이미지 영역이 커지는 경우 화질 저하를 경험할 수 있음.
const minImageSize = 54;

const BaseProductHorizontalCard = forwardRef<HTMLDivElement, ProductHorizontalCardProps>(
  (
    {
      className,
      imgUrl,
      brandName,
      productName,
      price,
      discount,
      postfix,
      extraBadge,
      onClick,
      soldOut,
      renderImage = ({ className, src }) => {
        // TODO: 이미지 최적화 적용하기
        return <img className={className} src={src} loading="lazy" />;
      },
    },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        className={cn('flex min-h-[56px] min-w-[240px] items-stretch gap-8', className)}
        onClick={onClick}
        role="button"
      >
        <div className="relative aspect-square min-w-[56px] flex-none overflow-hidden rounded-2 border border-solid border-line">
          {extraBadge ? (
            <Badge className="absolute bottom-0 left-0 z-content-1" size="small" priority="primary">
              {extraBadge}
            </Badge>
          ) : null}
          {renderImage({
            className: 'absolute left-0 top-0 h-full w-full object-fill',
            src: imgUrl,
            imageSize: minImageSize,
          })}
        </div>
        <div className="flex min-w-0 flex-1 items-center">
          <div className="flex min-w-0 flex-col items-start gap-2">
            {brandName ? <p className="w-full truncate text-xs-bold">{brandName}</p> : null}
            {productName ? <p className="w-full truncate text-s">{productName}</p> : null}
            <PriceInfoSection soldOut={soldOut} price={price} discount={discount} />
          </div>
        </div>
        {postfix ? <ProductHorizontalCardPostfix postfix={postfix} /> : null}
      </div>
    );
  },
);

export const ProductHorizontalCard = memo(BaseProductHorizontalCard);

const PriceInfoSection = ({
  soldOut,
  price,
  discount,
}: Pick<ProductHorizontalCardProps, 'price' | 'discount' | 'soldOut'>) => {
  const info = productHorizontalCardInfo({
    soldOut,
  });

  if (!price) return null;

  return (
    <div className="flex content-baseline items-center gap-2 text-s-bold">
      {soldOut && (
        <Badge size="small" priority="tertiary">
          임시품절
        </Badge>
      )}
      {discount ? <p className={info.discount()}>{discount}%</p> : null}
      <p className={info.priceInfo()}>{commaizeNumber(price)}</p>
    </div>
  );
};
