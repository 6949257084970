import { Config } from 'tailwindcss';

export const scaleToken = {
  'transparent': 'transparent',
  'blue-100': { DEFAULT: '#ebf1ff', light: '#ebf1ff', dark: '#000033' },
  'blue-200': { DEFAULT: '#b8cdff', light: '#b8cdff', dark: '#01058b' },
  'blue-300': { DEFAULT: '#86a5ff', light: '#86a5ff', dark: '#0510be' },
  'blue-400': { DEFAULT: '#5b7fff', light: '#5b7fff', dark: '#0e22dd' },
  'blue-500': { DEFAULT: '#375fff', light: '#375fff', dark: '#1e3cf2' },
  'blue-600': { DEFAULT: '#203ff6', light: '#203ff6', dark: '#375fff' },
  'blue-700': { DEFAULT: '#1025e5', light: '#1025e5', dark: '#5f83ff' },
  'blue-800': { DEFAULT: '#0611bd', light: '#0611bd', dark: '#8dabff' },
  'blue-900': { DEFAULT: '#010474', light: '#010474', dark: '#bdd1ff' },
  'blue-950': { DEFAULT: '#000033', light: '#000033', dark: '#ebf1ff' },
  'blue-alpha-200': { DEFAULT: '#345fff14', light: '#345fff14', dark: '#bdd1ff14' },
  'blue-alpha-300': { DEFAULT: '#345fff29', light: '#345fff29', dark: '#bdd1ff29' },
  'blue-alpha-400': { DEFAULT: '#345fff3d', light: '#345fff3d', dark: '#bdd1ff3d' },
  'gray-00': { DEFAULT: '#ffffff', light: '#ffffff', dark: '#000000' },
  'gray-100': { DEFAULT: '#f4f4f4', light: '#f4f4f4', dark: '#19191A' },
  'gray-200': { DEFAULT: '#e4e4e4', light: '#e4e4e4', dark: '#27272A' },
  'gray-300': { DEFAULT: '#c4c4c4', light: '#c4c4c4', dark: '#303033' },
  'gray-400': { DEFAULT: '#a0a0a0', light: '#a0a0a0', dark: '#474747' },
  'gray-500': { DEFAULT: '#5d5d5d', light: '#5d5d5d', dark: '#5d5d5d' },
  'gray-600': { DEFAULT: '#474747', light: '#474747', dark: '#a0a0a0' },
  'gray-700': { DEFAULT: '#303033', light: '#303033', dark: '#c4c4c4' },
  'gray-800': { DEFAULT: '#27272A', light: '#27272A', dark: '#e4e4e4' },
  'gray-900': { DEFAULT: '#19191A', light: '#19191A', dark: '#f4f4f4' },
  'gray-950': { DEFAULT: '#000000', light: '#000000', dark: '#ffffff' },
  'gray-alpha-200': { DEFAULT: '#5d5d5d14', light: '#5d5d5d14', dark: '#a0a0a014' },
  'gray-alpha-300': { DEFAULT: '#5d5d5d29', light: '#5d5d5d29', dark: '#a0a0a029' },
  'gray-alpha-400': { DEFAULT: '#5d5d5d3d', light: '#5d5d5d3d', dark: '#a0a0a03d' },
  'green-100': { DEFAULT: '#eefee6', light: '#eefee6', dark: '#1b2e11' },
  'green-200': { DEFAULT: '#c2eeb2', light: '#c2eeb2', dark: '#234a15' },
  'green-300': { DEFAULT: '#81dc71', light: '#81dc71', dark: '#246616' },
  'green-400': { DEFAULT: '#3fcb3c', light: '#3fcb3c', dark: '#1e8214' },
  'green-500': { DEFAULT: '#12ba23', light: '#12ba23', dark: '#129e14' },
  'green-600': { DEFAULT: '#139e14', light: '#139e14', dark: '#12ba23' },
  'green-700': { DEFAULT: '#1e8215', light: '#1e8215', dark: '#42cb3e' },
  'green-800': { DEFAULT: '#246616', light: '#246616', dark: '#85dd76' },
  'green-900': { DEFAULT: '#234a15', light: '#234a15', dark: '#c5eeb6' },
  'green-950': { DEFAULT: '#1b2e11', light: '#1b2e11', dark: '#EEFEE6' },
  'green-alpha-200': { DEFAULT: '#12ba2314', light: '#12ba2314', dark: '#85dd7614' },
  'green-alpha-300': { DEFAULT: '#12ba2329', light: '#12ba2329', dark: '#85dd7629' },
  'green-alpha-400': { DEFAULT: '#12ba233d', light: '#12ba233d', dark: '#85dd763d' },
  'red-100': { DEFAULT: '#ffefeb', light: '#ffefeb', dark: '#2e1608' },
  'red-200': { DEFAULT: '#ffbfaa', light: '#ffbfaa', dark: '#582509' },
  'red-300': { DEFAULT: '#ff916c', light: '#ff916c', dark: '#822f07' },
  'red-400': { DEFAULT: '#ff6933', light: '#ff6933', dark: '#ab3703' },
  'red-500': { DEFAULT: '#ff4800', light: '#ff4800', dark: '#d53e00' },
  'red-600': { DEFAULT: '#d53f00', light: '#d53f00', dark: '#ff4800' },
  'red-700': { DEFAULT: '#ab3803', light: '#ab3803', dark: '#ff6c37' },
  'red-800:': { DEFAULT: '#823107', light: '#823107', dark: '#ff9672' },
  'red-900:': { DEFAULT: '#582609', light: '#582609', dark: '#ffc3b0' },
  'red-950:': { DEFAULT: '#2e1608', light: '#2e1608', dark: '#ffefeb' },
  'red-alpha-200': { DEFAULT: '#ff480014', light: '#ff480014', dark: '#ffc3b014' },
  'red-alpha-300': { DEFAULT: '#ff480029', light: '#ff480029', dark: '#ffc3b029' },
  'red-alpha-400': { DEFAULT: '#ff48003d', light: '#ff48003d', dark: '#ffc3b03d' },
  'yellow-100:': { DEFAULT: '#fff7f0', light: '#fff7f0', dark: '#1a130c' },
  'yellow-200': { DEFAULT: '#ffdfb9', light: '#ffdfb9', dark: '#47341c' },
  'yellow-300': { DEFAULT: '#ffc97f', light: '#ffc97f', dark: '#755323' },
  'yellow-400': { DEFAULT: '#ffb741', light: '#ffb741', dark: '#a37122' },
  'yellow-500': { DEFAULT: '#ffa800', light: '#ffa800', dark: '#d18d17' },
  'yellow-600': { DEFAULT: '#d18d16', light: '#d18d16', dark: '#ffa800' },
  'yellow-700': { DEFAULT: '#a37021', light: '#a37021', dark: '#ffb741' },
  'yellow-800': { DEFAULT: '#755322', light: '#755322', dark: '#ffc97f' },
  'yellow-900': { DEFAULT: '#47341b', light: '#47341b', dark: '#ffdfb9' },
  'yellow-950': { DEFAULT: '#19130c', light: '#19130c', dark: '#fff7f0' },
};

// https://github.com/29CM-Developers/design-system-figma-token-sync/blob/main/tokens/static-scale/color.json
const staticToken = {
  black: '#000000',
  white: '#ffffff',
  ['white-alpha-200']: '#FFFFFF20',
  ['white-alpha-500']: '#FFFFFF80',
  ['white-alpha-900']: '#FFFFFFe6',
  ['black-alpha-200']: '#00000020',
  ['black-alpha-500']: '#00000080',
  ['black-alpha-900']: '#000000e6',
} as const;

/**
 * @description
 * Ruler에서 사용하는 공용 컬러 토큰입니다.
 */

const commonToken = {
  ...staticToken,
  ['inherit']: 'inherit',
  ['transparent']: 'transparent',
  ['on-black']: staticToken.black,
  ['on-black-hover']: staticToken['black-alpha-900'],
  ['on-black-pressed']: staticToken['black-alpha-900'],
  ['on-white']: staticToken.white,
  ['on-white-hover']: staticToken['white-alpha-900'],
  ['on-white-pressed']: staticToken['white-alpha-900'],
  ['interactive-low']: scaleToken['blue-alpha-200'],
  ['interactive']: scaleToken['blue-500'],
  ['interactive-hover']: scaleToken['blue-400'],
  ['interactive-pressed']: scaleToken['blue-400'],
  ['negative']: scaleToken['red-600'],
  ['negative-hover']: scaleToken['red-400'],
  ['negative-pressed']: scaleToken['red-400'],
  ['accent-low']: scaleToken['red-alpha-200'],
  ['accent']: scaleToken['red-500'],
  ['positive-low']: scaleToken['green-alpha-200'],
  ['positive']: scaleToken['green-500'],
  ['positive-hover']: scaleToken['green-400'],
  ['positive-pressed']: scaleToken['green-400'],
} as const;

export const colorTheme = {
  colors: { ...scaleToken, ...commonToken },
  borderColor: ({ theme }) => ({
    ...theme('colors'),
    line: theme('colors.gray-200'),
    ['line-hover']: theme('colors.gray-400'),
    ['line-pressed']: theme('colors.gray-400'),
    ['line-selected']: theme('colors.gray-950'),
    ['line-disabled']: theme('colors.gray-100'),
  }),
  divideColor: ({ theme }) => ({
    ...theme('colors'),
    ['default']: theme('colors.gray-100'),
    ['strong']: theme('colors.gray-950'),
  }),
  backgroundColor: ({ theme }) => ({
    ...theme('colors'),
    ['low']: theme('colors.gray-100'),
    ['default']: theme('colors.gray-00'),
    ['default-black']: theme('colors.gray-950'),
    ['primary']: theme('colors.gray-950'),
    ['primary-hover']: theme('colors.gray-800'),
    ['primary-pressed']: theme('colors.gray-800'),
    ['secondary']: theme('colors.gray-700'),
    ['tertiary']: theme('colors.gray-100'),
    ['silent']: theme('colors.gray-00'),
    ['silent-hover']: theme('colors.gray-100'),
    ['silent-pressed']: theme('colors.gray-100'),
    ['surface-contents']: theme('colors.gray-100'),
    ['surface-floating']: theme('colors.gray-950'),
    ['surface-dialog']: theme('colors.gray-00'),
    ['surface-sheet']: theme('colors.gray-00'),
    ['surface-black']: theme('colors.gray-00'),
    ['disabled']: theme('colors.gray-100'),
    ['dimmed']: staticToken['black-alpha-500'],
    ['blur-ultrathin']: 'rgba(191, 191, 191, 0.44)',
    ['blur-ultrathin-black']: 'rgba(93, 88, 81, 0.66)',
  }),
  textColor: ({ theme }) => ({
    ...theme('colors'),
    ['primary']: theme('colors.gray-950'),
    ['primary-hover']: theme('colors.gray-600'),
    ['primary-pressed']: theme('colors.gray-600'),
    ['secondary']: theme('colors.gray-600'),
    ['secondary-hover']: theme('colors.gray-500'),
    ['secondary-pressed']: theme('colors.gray-500'),
    ['tertiary']: theme('colors.gray-400'),
    ['tertiary-hover']: theme('colors.gray-300'),
    ['tertiary-pressed']: theme('colors.gray-300'),
    ['disabled']: theme('colors.gray-300'),
    ['on-color']: theme('colors.gray-00'),
  }),
  accentColor: ({ theme }) => ({
    ...theme('colors'),
    ['low']: theme('colors.red-alpha-200'),
    ['default']: theme('colors.red-500'),
  }),
} satisfies Config['theme'];
