'use client';

import { cn } from '@29cm/configs/tailwind-variants';
import { useIsMdScreen, useIsSmScreen } from '@29cm/contexts-common-hooks';
import { useIsMounted } from '@29cm/hooks-dom';
import { ReactNode } from 'react';

type Props = {
  className?: string;
  sm?: ReactNode;
  md?: ReactNode;
};

export const Responsive = ({ className, sm, md }: Props) => {
  const isMounted = useIsMounted();
  const isSmScreen = useIsSmScreen();
  const isMdScreen = useIsMdScreen();

  const shouldRenderSm = (!isMounted || isSmScreen) && sm !== undefined;
  const shouldRenderMd = (!isMounted || isMdScreen) && md !== undefined;

  return (
    <>
      {shouldRenderSm ? <div className={cn(`block md:hidden`, className)}>{sm}</div> : null}
      {shouldRenderMd ? <div className={cn(`hidden md:block`, className)}>{md}</div> : null}
    </>
  );
};
