'use client';

import { cn } from '@29cm/configs/tailwind-variants';
import { EmoIcon } from '@29cm/contexts-common-ruler';
import { ComponentProps } from 'react';

type Props = Pick<ComponentProps<'button'>, 'onClick' | 'disabled' | 'className'> & {
  direction: 'previous' | 'next';
};

export const BannerCarouselNavigator = ({ className, disabled, direction, onClick }: Props) => {
  return (
    <button className={cn('disabled:cursor-not-allowed', className)} disabled={disabled} onClick={onClick}>
      <EmoIcon
        className="max-[375px]:!h-[24px] max-[375px]:!min-h-[24px] max-[375px]:!w-[24px] max-[375px]:!min-w-[24px]"
        type={direction === 'previous' ? 'chevronLeft' : 'chevronRight'}
        fill={false}
        size={40}
        color="onColor"
      />
    </button>
  );
};
