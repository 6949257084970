import { firebase, snowplow } from '@29cm/contexts-analytics/services';
import { SCREEN, WIDGET } from '../constants';
import { createEvent } from './createEvent';

export const trackVisitHomeMainPageEvent = createEvent({
  name: 'visit_home_main_page',
  trackers: [
    firebase.track,
    snowplow.track({
      name: 'iglu:com.29cm/visit_home_main/jsonschema/1-0-0',
      properties: {
        current_screen: SCREEN.HOME_MAIN,
        home_tab: WIDGET.HOME_TAB,
      },
    }),
  ],
});

export const trackClickCouponTriggerEvent = createEvent({
  name: 'click_coupon_trigger',
  trackers: [
    firebase.track({
      properties: { curr_screen: SCREEN.HOME_MAIN },
    }),
  ],
});

export const trackViewCouponTriggerEvent = createEvent({
  name: 'view_coupon_trigger',
  trackers: [
    firebase.track({
      properties: { curr_screen: SCREEN.HOME_MAIN },
    }),
  ],
});
