import { HeartBoldFillIcon, HeartBoldIcon } from '@29cm/contexts-common-icon';
import { ReactElement, useCallback } from 'react';
import { Badge } from '../Badge';

export interface PreviewProps {
  imgUrl: string;
  onHeart: boolean;
  onHeartClick?: () => void;
  extraBadge?: string;
  renderImage?: (props: { className: string; src: string }) => ReactElement;
}

export const Preview = ({
  onHeart = false,
  onHeartClick,
  imgUrl,
  extraBadge,
  renderImage = ({ className, src }) => {
    // TODO: 이미지 최적화 적용하기
    return <img className={className} src={src} loading="lazy" />;
  },
}: PreviewProps) => {
  const handleHeartClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault(); // 기본 동작 방지 (e.g. 부모 요소 링크 이동)
      e.stopPropagation();
      onHeartClick?.();
    },
    [onHeartClick],
  );

  return (
    <div className="preview relative aspect-square">
      {renderImage({
        className: 'object-fit w-full h-full rounded-2',
        src: imgUrl,
      })}
      <div className="absolute inset-x-0 bottom-0">{extraBadge && <ExtraBadge label={extraBadge} />}</div>
      {/* TODO:(신다혜) - LikeButton이 제작되면 교체가 필요합니다. */}
      <button className="heart-icon-shadow absolute bottom-10 right-10" onClick={handleHeartClick}>
        {onHeart ? (
          <HeartBoldFillIcon className="text-accent" size={24} />
        ) : (
          <HeartBoldIcon className="text-on-white" size={24} />
        )}
      </button>
    </div>
  );
};

const ExtraBadge = ({ label }: { label: string }) => {
  return (
    <div className="absolute bottom-10 left-10">
      <Badge size="medium" priority="primary">
        {label}
      </Badge>
    </div>
  );
};
