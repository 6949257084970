import { useAuthenticationContext } from '@29cm/contexts-auth/features';
import { showLoginDialog } from '@29cm/contexts-auth/utils';
import { ProductCard, ProductCardProps } from '@29cm/contexts-common-ruler';
import { Image } from '@29cm/contexts-common-views';
import { useProductHeart } from '@29cm/contexts-hearts-features';
import { memo } from 'react';

export type ProductCardWithHeartProps = ProductCardWithLoginProps;

export const ProductCardWithHeart = memo((props: ProductCardWithHeartProps) => {
  const { isLogged } = useAuthenticationContext();

  if (isLogged) {
    return <ProductCardWithLogin {...props} />;
  }

  return (
    <ProductCard
      {...props}
      onHeart={false}
      onHeartClick={showLoginDialog}
      renderImage={({ className, src }) => <Image className={className} src={src} alt="" fill />}
    />
  );
});

interface ProductCardWithLoginProps extends Omit<ProductCardProps, 'onHeart' | 'onHeartClick'> {
  productId: number;
  onHeartChange?: (isHeartOn: boolean) => void;
}

const ProductCardWithLogin = memo(({ productId, likeAmount, onHeartChange, ...rest }: ProductCardWithLoginProps) => {
  const { isHeartOn, heartCount, toggleHeart } = useProductHeart(productId, likeAmount ?? 0, onHeartChange);

  return (
    <ProductCard
      {...rest}
      onHeart={isHeartOn}
      likeAmount={heartCount}
      onHeartClick={toggleHeart}
      renderImage={({ className, src }) => <Image className={className} src={src} alt="" fill />}
    />
  );
});
