import { tv } from '@29cm/configs/tailwind-variants';
import { unitizeNumber } from '@29cm/contexts-common-utils';
import { Icon } from '@29cm/ruler';
import { forwardRef, memo } from 'react';
import { LikeCountButtonProps } from './LikeCountButton.types';

const likeCountButton = tv({
  slots: {
    layout:
      'group m-0 flex h-[52px] min-h-[52px] w-[52px] min-w-[52px] flex-col items-center justify-center rounded-4 border-none outline-none focus-visible:border focus-visible:border-solid focus-visible:border-interactive',
    icon: 'transition duration-150 ease-in-out group-active:h-[22px] group-active:min-h-[22px] group-active:w-[22px] group-active:min-w-[22px]',
    count: 'w-full truncate text-s-medium',
  },
  variants: {
    priority: {
      default: {
        layout: 'text-tertiary',
        icon: '[&>g>*]:group-hover:fill-tertiary-hover [&>g>*]:group-active:fill-tertiary-pressed',
        count: 'group-hover:text-tertiary-hover group-active:text-tertiary-pressed',
      },
      white: {
        layout: 'text-on-color',
        icon: '[&>g>*]:group-hover:fill-on-color-hover [&>g>*]:group-active:fill-on-color-pressed',
        count: 'group-hover:text-on-color-hover group-active:text-on-color-pressed',
      },
    },
    on: {
      true: {
        icon: '[&>g>*]:fill-accent [&>g>*]:group-hover:fill-accent [&>g>*]:group-active:fill-accent',
      },
    },
  },
});

const BaseLikeCountButton = forwardRef<HTMLButtonElement, LikeCountButtonProps>(
  ({ className, count, on, priority, onClick, ...rest }, ref) => {
    const button = likeCountButton({ priority });

    return (
      <button ref={ref} className={button.layout({ class: className })} onClick={onClick} {...rest}>
        <Icon
          className={button.icon({ on })}
          type="heart"
          fill={on}
          weight={on ? 'bold' : 'regular'}
          size={24}
          color={priority === 'default' ? 'tertiary' : 'onColor'}
        />
        <p className={button.count()}>{unitizeNumber(count)}</p>
      </button>
    );
  },
);

export const LikeCountButton = memo(BaseLikeCountButton);
