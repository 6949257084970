import { createPromiseStore } from '@29cm/contexts-common-stores';
import { createObserver } from '@29cm/utils-functions';
import { isDevelopment } from '@29cm/utils-node';
import { Analytics, AnalyticsPlatform, Properties } from '../interfaces';

type AnalyticsOptions<T, K extends string, P extends Properties> = {
  /**
   * @description 애널리틱스 플랫폼명
   * @example 'firebase' | 'amplitude' | 'braze'
   */
  platform: AnalyticsPlatform;

  /**
   * @description 애널리틱스 SDK 의 초기화 함수
   * @returns {(Instance | Promise<Instance>)} SDK 자체의 인스턴스를 반환해야 합니다.
   */
  initializer: () => T | Promise<T>;

  /**
   * @description 애널리틱스 SDK 의 커스텀 이벤트 수집 함수
   * @param {Instance} instance initializer 에서 반환된 인스턴스를 전달합니다.
   * @param {string} name 커스텀 이벤트명
   * @param {object} properties 커스텀 이벤트 프로퍼티
   */
  tracker: (name: K, properties?: P, instance?: T) => void;

  /**
   * @description 애널리틱스 SDK 의 user property 설정 함수 (선택)
   * @param {Instance} instance initializer 에서 반환된 인스턴스를 전달합니다.
   * @param {string} userId 유저 아이디
   */
  userSetter?: (userId: string, instance?: T) => void;
};

/**
 * @description 각각 사용 방법이 다른 애널리틱스 SDK 들의 동작과 사용법의 일관성을 맞추기 위한 함수입니다.
 * 
 * 애널리틱스 SDK 가 초기화 되기 전에 tracker, userSetter 가 호출될 경우 초기화가 완료된 후에 일괄 실행됩니다.
 * 
 * @example
 * ```ts
 *export const firebase = createAnalytics({
    platform: 'firebase',
    initializer: () => {
      ...
      return getAnalytics(getApp(FIREBASE_APP_NAME));
    },
    tracker: (instance, name, properties) => {
      logEvent(instance, name, properties);
    },
    userSetter: (instance, userId) => {
      setUserId(instance, userId);
      setUserProperties(instance, { user_id: userId });
    },
  });
 * ```
 */
export const createAnalytics = <T, K extends string = string, P extends Properties = Properties>(
  options: AnalyticsOptions<T, K, P>,
): Analytics<K, P> => {
  const { platform, initializer, tracker, userSetter } = options;

  const observer = createObserver();
  const { batch } = createPromiseStore(platform);

  let isInitialized = false;
  let instance: T | undefined;

  const init = batch(async () => {
    try {
      if (isInitialized) {
        return;
      }

      instance = await initializer();
      isInitialized = true;

      // instance 가 생성되면 구독 함수들을 일괄 실행합니다.
      observer.notify(platform, instance);
    } catch (error) {
      console.error(`Error occurred while initializing ${platform}: `, error);
      isInitialized = false;
      return;
    }
  });

  const setUser = (userId: string) => {
    if (userSetter === undefined) {
      return;
    }

    if (isInitialized) {
      userSetter?.(userId, instance);
      return;
    }

    if (isDevelopment()) {
      console.error(`setUser called before initializing ${platform}`);
    }

    // 초기화가 완료되기 전에 실행된 경우, 초기화된 후에 실행되도록 합니다.
    observer.observe<T>(platform, (instance) => userSetter?.(userId, instance), { priority: 'high' });
  };

  const track = (name: K, properties?: P) => {
    if (isInitialized) {
      tracker(name, properties, instance);
      return;
    }

    if (isDevelopment()) {
      console.error(`track called before initializing ${platform}`);
    }

    // 초기화가 완료되기 전에 실행된 경우, 초기화된 후에 실행되도록 합니다.
    observer.observe<T>(platform, (instance) => tracker(name, properties, instance));
  };

  return {
    init,
    setUser,
    /**
     * NOTE: track 함수는 복수의 시그니처를 가지며, 이 경우 구현체에서는 첫 번째 시그니처의 타입으로만 한정되어 반환 타입을 any 로 선언합니다.
     *
     * 실제 사용처에서는 매개변수 타입에 맞는 반환 타입으로 올바르게 추론됩니다.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    track: <P1 extends P, P2 extends Properties>(arg: K | { name?: K; properties?: P1 | ((value: P2) => P1) }): any => {
      if (typeof arg === 'string') {
        return track(arg);
      }

      const { name: overridableName, properties } = arg;

      if (typeof properties === 'function') {
        const transformer = properties;

        return (name: K, properties: P2) => track(overridableName ?? name, transformer(properties));
      }

      if (typeof properties === 'object') {
        return (name: K) => track(overridableName ?? name, properties);
      }

      return (name: K) => track(overridableName ?? name);
    },
  };
};
