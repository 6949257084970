import { getCampaignLink } from '@29cm/contexts-common-utils';
import { Link } from '@29cm/contexts-common-views';
import { Feed } from '@29cm/contexts-feeds-services';
import { BannerFeedLayout } from '@29cm/contexts-feeds-views';

interface Props {
  item: Feed;
  position: number;
}

export const BannerFeed = ({ item, position }: Props) => {
  const href = getCampaignLink(item.linkType, item.linkValue);

  return (
    <Link href={href}>
      <BannerFeedLayout feed={item} position={position} />
    </Link>
  );
};
