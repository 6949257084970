import { appEnv } from './appEnv';

export const apiPrefixPath = appEnv === 'production' ? '' : appEnv === 'qa' ? 'qa' : 'dev';
export const apihubApiPath =
  appEnv === 'production'
    ? 'https://apihub.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://apihub.29cm.co.kr/qa'
      : 'https://apihub.29cm.co.kr/dev';
export const apihubCacheApiPath =
  appEnv === 'production'
    ? 'https://cache.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://cache.29cm.co.kr/qa'
      : 'https://cache.29cm.co.kr/dev';
export const commerceApiPath =
  appEnv === 'production'
    ? 'https://commerce-api.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://qa-commerce-api.29cm.co.kr'
      : 'https://dev-commerce-api.29cm.co.kr';
export const orderApiPath =
  appEnv === 'production'
    ? 'https://order-api.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://qa-order-api.29cm.co.kr'
      : 'https://dev-order-api.29cm.co.kr';
export const surveyApiPath =
  appEnv === 'production'
    ? 'https://survey-api.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://qa-survey-api.29cm.co.kr'
      : 'https://dev-survey-api.29cm.co.kr';
export const itemApiPath =
  appEnv === 'production'
    ? 'https://item-api.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://qa-item-api.29cm.co.kr'
      : 'https://dev-item-api.29cm.co.kr';
export const frontApiPath =
  appEnv === 'production'
    ? 'https://front-api.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://qa-front-api.29cm.co.kr'
      : 'https://dev-front-api.29cm.co.kr';
export const inboxApiPath =
  appEnv === 'production'
    ? 'https://inbox-api.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://qa-inbox-api.29cm.co.kr'
      : 'https://dev-inbox-api.29cm.co.kr';
export const contentApiPath =
  appEnv === 'production'
    ? 'https://content-api.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://qa-content-api.29cm.co.kr'
      : 'https://dev-content-api.29cm.co.kr';
export const userApiPath =
  appEnv === 'production'
    ? 'https://user-api.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://qa-user-api.29cm.co.kr'
      : 'https://dev-user-api.29cm.co.kr';
export const authApiPath =
  appEnv === 'production'
    ? 'https://auth-api.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://qa-auth-api.29cm.co.kr'
      : 'https://dev-auth-api.29cm.co.kr';
export const contactApiPath =
  appEnv === 'production'
    ? 'https://partner-api.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://qa-partner-api.29cm.co.kr'
      : 'https://dev-partner-api.29cm.co.kr';
export const promotionApiPath =
  appEnv === 'production'
    ? 'https://promotion-api.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://qa-promotion-api.29cm.co.kr'
      : 'https://dev-promotion-api.29cm.co.kr';
export const logisticsApiPath =
  appEnv === 'production'
    ? 'https://logistics-api.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://qa-logistics-api.29cm.co.kr'
      : 'https://dev-logistics-api.29cm.co.kr';
export const activationApiPath =
  appEnv === 'production'
    ? 'https://activation-api.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://qa-activation-api.29cm.co.kr'
      : 'https://dev-activation-api.29cm.co.kr';
export const recommendApiPath =
  appEnv === 'production'
    ? 'https://recommend-api.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://qa-recommend-api.29cm.co.kr'
      : 'https://dev-recommend-api.29cm.co.kr';
export const bookingItemApiPath =
  appEnv === 'production'
    ? 'https://booking-item-api.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://qa-booking-item-api.29cm.co.kr'
      : 'https://dev-booking-item-api.29cm.co.kr';
export const reviewApiPath =
  appEnv === 'production'
    ? 'https://review-api.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://qa-review-api.29cm.co.kr'
      : 'https://dev-review-api.29cm.co.kr';
export const momentTriggerApiPath =
  appEnv === 'production'
    ? 'https://moment-trigger-api.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://qa-moment-trigger-api.29cm.co.kr'
      : 'https://dev-moment-trigger-api.29cm.co.kr';
export const interactionCollectorApiPath =
  appEnv === 'production'
    ? 'https://interaction-collector-api.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://qa-interaction-collector-api.29cm.co.kr'
      : 'https://dev-interaction-collector-api.29cm.co.kr';
export const searchApiPath =
  appEnv === 'production'
    ? 'https://search-api.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://qa-search-api.29cm.co.kr'
      : 'https://dev-search-api.29cm.co.kr';
export const giftApiPath =
  appEnv === 'production'
    ? 'https://gift-api.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://qa-gift-api.29cm.co.kr'
      : 'https://dev-gift-api.29cm.co.kr';
export const claimApiPath =
  appEnv === 'production'
    ? 'https://claim-api.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://qa-claim-api.29cm.co.kr'
      : 'https://dev-claim-api.29cm.co.kr';
export const collectorApiPath =
  appEnv === 'production'
    ? 'https://dataplatform-collector.29cm.co.kr'
    : 'https://dev-dataplatform-collector.29cm.co.kr';
export const widgetApiPath =
  appEnv === 'production'
    ? 'https://widget-api.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://qa-widget-api.29cm.co.kr'
      : 'https://dev-widget-api.29cm.co.kr';
export const bffApiPath =
  appEnv === 'production'
    ? 'https://bff-api.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://qa-bff-api.29cm.co.kr'
      : 'https://dev-bff-api.29cm.co.kr';
export const csSupportApiPath =
  appEnv === 'production'
    ? 'https://cs-support-api.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://qa-cs-support-api.29cm.co.kr'
      : 'https://dev-cs-support-api.29cm.co.kr';
export const unleashApiPath =
  appEnv === 'production' ? 'https://flag.29cm.co.kr/proxy' : 'https://dev-flag.29cm.co.kr/proxy';
