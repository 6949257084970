import { ContentsFeedLayout } from '@29cm/contexts-feeds-views';

import { Feed } from '@29cm/contexts-feeds-services';
import { ContentsFeedItem } from './ContentsFeedItem';

interface Props {
  item: Feed;
  position: number;
}

export const ContentsFeed = ({ item, position }: Props) => {
  const { relatedProducts } = item;

  return (
    <ContentsFeedLayout className="overflow-hidden px-24 py-32" feed={item} position={position}>
      <ul className="flex flex-col gap-10">
        {relatedProducts.map((product, index) => (
          // index 와 상품 아이디를 조합, 동일 피드 중복 상품이 있는 케이스 대응 합니다.
          // eslint-disable-next-line react/no-array-index-key
          <ContentsFeedItem
            key={`${index}-${product.id}`}
            product={product}
            feed={item}
            position={index + 1}
            feedPosition={position}
          />
        ))}
      </ul>
    </ContentsFeedLayout>
  );
};
