'use client';

import { transparentFallbackImageSrc } from '@29cm/contexts-common-constants';
import { getCampaignLink, getImageUrl } from '@29cm/contexts-common-utils';
import { Link } from '@29cm/contexts-common-views';
import { SCREEN } from '@29cm/contexts-events/constants';
import {
  trackClickFeedProductEvent,
  trackImpressionFeedProductEvent,
  trackLikeProductEvent,
  trackUnlikeProductEvent,
} from '@29cm/contexts-events/services';
import { Feed, FeedProduct } from '@29cm/contexts-feeds-services';
import { ProductCardWithHeart } from '@29cm/contexts-products/features';
import { Screen } from '@29cm/tracker';

interface Props {
  product: FeedProduct;
  feed: Feed;
  position: number;
  feedPosition: number;
}

export const MultiProductCardCarouseItem = ({ feed, product, position, feedPosition }: Props) => {
  const { images, frontBrand, name, sale, heartCount, reviewCount, id } = product;
  const [image] = images;

  const imageUrl = image !== undefined ? `${getImageUrl(image.uploadName)}?width=300` : transparentFallbackImageSrc;
  const href = getCampaignLink('product', id);

  const handleHeartChange = (isHeartOn: boolean) => {
    if (isHeartOn) {
      trackLikeProductEvent({ product, screen: SCREEN.HOME_MAIN });
    } else {
      trackUnlikeProductEvent({ product, screen: SCREEN.HOME_MAIN });
    }
  };

  const handleImpressed = () => trackImpressionFeedProductEvent({ feed, product, position, feedPosition });

  const handleClick = () => trackClickFeedProductEvent({ feed, product, position, feedPosition });

  return (
    <Screen.Target onImpressed={handleImpressed}>
      <Link href={href} onClick={handleClick}>
        <ProductCardWithHeart
          productId={id}
          imgUrl={imageUrl}
          productName={name}
          price={sale.totalSellPrice}
          discount={sale.totalSaleRate}
          brandName={frontBrand.nameKor}
          likeAmount={heartCount}
          reviewAmount={reviewCount}
          onHeartChange={handleHeartChange}
        />
      </Link>
    </Screen.Target>
  );
};
