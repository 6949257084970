'use client';

import { useCookieStore } from '@29cm/contexts-cookie/hooks';
import { add } from '@29cm/utils-dates';
import { useSearchParams } from 'next/navigation';
import { useEffect, useMemo } from 'react';

export enum NaverCampaignType {
  SHOPPING_KEYWORD = 2,
}

// TODO: 관련 맥락을 기입해둘 것
export const NaverEpManager: React.FC = () => {
  const searchParams = useSearchParams();

  const key = 'naverEp';
  const cookie = useCookieStore([key]);

  const naverCode = searchParams.get('naver_code');
  const naverCampaignType = Number(searchParams.get('n_campaign_type') ?? 0);
  const naverEp = useMemo(() => {
    if (naverCampaignType === NaverCampaignType.SHOPPING_KEYWORD) {
      return 'naver_shopping_keyword';
    }

    if (naverCode) {
      return naverCode;
    }

    return cookie.cookies[0];
  }, [naverCampaignType, naverCode, cookie.cookies]);

  useEffect(() => {
    if (naverEp) {
      cookie.setCookies({
        [key]: {
          value: naverEp,
          options: {
            end: add(new Date(), { seconds: 600 }),
            domain: '.29cm.co.kr',
            path: '/',
          },
        },
      }); // 10분만 유지
    }
  }, [cookie, naverEp]);

  return <></>;
};
