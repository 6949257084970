import { collectorApiPath } from '@29cm/contexts-common-constants';
import { isWebview } from '@29cm/contexts-runtime/services';
import {
  ActivityTrackingConfiguration,
  SelfDescribingJson,
  TrackerConfiguration,
  enableActivityTracking,
  newSession,
  newTracker,
  setUserId,
  trackSelfDescribingEvent as trackBrowserEvent,
} from '@snowplow/browser-tracker';
import { trackSelfDescribingEvent as trackWebviewEvent } from '@snowplow/webview-tracker';
import { createAnalytics } from './createAnalytics';

const SNOWPLOW_TRACKER_ID = 'web';

const SNOWPLOW_TRACKER_CONFIG: Readonly<TrackerConfiguration> = {
  anonymousTracking: false,
  contexts: {
    webPage: false,
    session: true,
    browser: false,
  },
  appId: '29cm',
  postPath: '/api/track',
  platform: 'web',
};

const SNOWPLOW_ACTIVITY_TRACKING_CONFIG: Readonly<ActivityTrackingConfiguration> = {
  minimumVisitLength: 30,
  heartbeatDelay: 10,
};

export const snowplow = createAnalytics({
  platform: 'snowplow',
  initializer: () => {
    newSession();
    newTracker(SNOWPLOW_TRACKER_ID, collectorApiPath, SNOWPLOW_TRACKER_CONFIG);

    // TODO: (임규민) analytics logger 구현

    enableActivityTracking(SNOWPLOW_ACTIVITY_TRACKING_CONFIG);
  },
  tracker: (schema, properties) => {
    const event: Readonly<SelfDescribingJson> = {
      schema,
      data: properties ?? {},
    };

    if (isWebview()) {
      trackWebviewEvent({ event });
    } else {
      trackBrowserEvent({ event });
    }
  },
  userSetter: (userId) => {
    setUserId(userId);
  },
});
