'use client';

import { tv } from '@29cm/configs/tailwind-variants';
import { Feed, FeedProduct } from '@29cm/contexts-feeds-services';
import { Carousel, CarouselRef } from '@29cm/contexts-home/views';
import { useCallback, useRef, useState } from 'react';
import { ProductCardCarouselItem } from './ProductCardCarouselItem';
import { ProductCardCarouselNavigator } from './ProductCardCarouselNavigator';
import { ProductCardCarouselProgress } from './ProductCardCarouselProgress';

interface Props {
  feed: Feed;
  items: FeedProduct[];
  position: number;
}

const carouselItemStyle = tv({
  base: 'transition duration-200 ease-out data-[focused=false]:-translate-x-1/4 data-[focused=false]:-translate-y-1/4 data-[focused=false]:scale-50 data-[focused=false]:opacity-40',
  variants: {
    hasMultiItem: {
      true: 'last:data-[focused=true]:-translate-x-[40%]',
    },
  },
});

export const ProductCardCarousel = ({ feed, items, position }: Props) => {
  const cardCarouselRef = useRef<CarouselRef>(null);
  const [focusedIndex, setFocusedIndex] = useState(0);

  const renderItem = (item: (typeof items)[number], index: number) => {
    return <ProductCardCarouselItem feed={feed} product={item} position={index + 1} feedPosition={position} />;
  };

  const handleFocusedIndexChange = useCallback((focusedIndex: number) => {
    setFocusedIndex(focusedIndex);
  }, []);

  return (
    <div className="relative">
      <div className="absolute bottom-[calc(100%+16px)] right-0 flex gap-4">
        <ProductCardCarouselNavigator
          direction="previous"
          disabled={focusedIndex === 0}
          onClick={() => {
            cardCarouselRef.current?.focusTo(focusedIndex - 1);
          }}
        />
        <ProductCardCarouselNavigator
          direction="next"
          disabled={focusedIndex === items.length - 1}
          onClick={() => {
            cardCarouselRef.current?.focusTo(focusedIndex + 1);
          }}
        />
      </div>

      <Carousel
        ref={cardCarouselRef}
        items={items}
        renderItem={renderItem}
        gap={6}
        snapAlign="start"
        snapStop="always"
        onFocusedIndexChange={handleFocusedIndexChange}
        itemsPerView={1.4}
        itemClassName={carouselItemStyle({ hasMultiItem: items.length > 1 })}
        alignItems="start"
      />

      <div className="absolute bottom-0 right-0">
        <ProductCardCarouselProgress current={focusedIndex + 1} max={items.length} />
      </div>

      <div
        className="absolute -right-20 top-0 h-[calc(100%-64px)] w-40"
        style={{ background: 'linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #FFF 50%)' }}
      />
    </div>
  );
};
