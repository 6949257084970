export const radiusTheme = {
  0: '0px',
  2: '2px',
  4: '4px',
  6: '6px',
  8: '8px',
  16: '16px',
  20: '20px',
  full: '9999px',
} as const;
