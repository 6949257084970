import { ClassValue, cn as baseCn, createTV, defaultConfig } from 'tailwind-variants';
import { theme } from '../tailwind/theme';
/**
 * NOTE:(신다혜) tailwindMergeConfig
 * @see {@link https://github.com/dcastil/tailwind-merge/blob/v2.2.0/docs/configuration.md#shape-of-tailwind-merge-config}
 */
const customTwMergeConfig = {
  classGroups: {
    'font-size': [...Object.keys(theme?.fontSize ?? {}).map((key) => `text-${key}`)],
  },
};

const tvConfig = {
  ...defaultConfig,
  twMergeConfig: customTwMergeConfig,
};

export type { ClassProp, ClassValue, VariantProps } from 'tailwind-variants';

/**
 * NOTE: tailwind-variant 대신 사용해주세요.
 * - 해당 래핑 함수가 아닌 tv를 이용하는 경우, 클래스 머지에 대한 사이드 이펙트가 발생할 수 있습니다.
 */
export const tv = createTV(tvConfig);

/**
 * NOTE: twMerge 대신 사용해주세요.
 * - 해당 래핑 함수가 아닌 twMerge를 이용하는 경우, 클래스 머지에 대한 사이드 이펙트가 발생할 수 있습니다.
 */
export const cn = (...classes: ClassValue[]) => baseCn(classes)(tvConfig);
