type Callback<T = unknown> = (arg: T) => void;

type ObserverOptions = Partial<{
  /**
   * @description callback 이 등록되는 우선순위를 정합니다.
   * @param high callback 이 가장 앞 순서에 등록됩니다.
   * @param normal callback 이 순차적으로 등록됩니다.
   */
  priority: 'high' | 'normal';
}>;

/**
 * @description 특정 이벤트의 구독이 필요한 경우 사용할 수 있는 함수입니다.
 *
 * @property {Function} notify 이벤트를 발생시키는 함수입니다.
 * @property {Function} observe 이벤트를 구독하는 함수입니다.
 *
 * @example
 * ```tsx
 * const observer = createObserver();
 *
 * const setNumber = (value: number) => {
 *   ...
 *   observer.notify(value);
 * }
 *
 * observer.observe<number>((value) => console.log(`값이 ${value} 로 변경되었습니다.`))
 *
 * setNumber(1); // 값이 1 로 변경되었습니다.
 * setNumber(2); // 값이 2 로 변경되었습니다.
 * setNumber(3); // 값이 3 로 변경되었습니다.
 * ```
 */
export const createObserver = () => {
  const callbackMap = new Map<string, Set<Callback>>();

  const notify = (event: string, arg?: unknown) => {
    const callbacks = callbackMap.get(event);

    callbacks?.forEach((callback) => callback(arg));
  };

  const observe = <T>(event: string, callback: Callback<T>, options?: ObserverOptions): (() => void) => {
    if (!callbackMap.has(event)) {
      callbackMap.set(event, new Set());
    }

    const callbacks = callbackMap.get(event);

    if (options?.priority === 'high') {
      const incoming = new Set([callback as Callback, ...Array.from((callbacks ?? new Set()).values())]);

      callbackMap.set(event, incoming);
    } else {
      callbacks?.add(callback as Callback);
    }

    return () => unobserve(event, callback as Callback);
  };

  const unobserve = (event: string, callback: Callback) => {
    const callbacks = callbackMap.get(event);

    callbacks?.delete(callback);

    if (callbacks?.size === 0) {
      callbackMap.delete(event);
    }
  };

  return {
    notify,
    observe,
  };
};
