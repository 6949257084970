const DEFAULT_FONT_PIXEL = 16;

const convertToRem = (value: number): string => {
  const remValue = value / DEFAULT_FONT_PIXEL;
  return remValue % 1 === 0 ? `${remValue}rem` : `${remValue.toFixed(3)}rem`;
};

const generateRemObject = (max: number): Record<number, string> => {
  return Array.from({ length: max / 2 + 1 }, (_, i) => i * 2).reduce(
    (acc, key) => {
      acc[key] = convertToRem(key);
      return acc;
    },
    {} as Record<number, string>,
  );
};

/**
 * @description 해당 값에서 사용되고 있는 dimension의 최대 사이즈를 사용합니다.
 * @see https://github.com/29CM-Developers/design-system-figma-token-sync/blob/main/tokens/scale/dimension-size.json
 */
const MAX_TOKEN_SIZE = 244;
const defaultSpacingStyle = {
  ['inherit']: 'inherit',
  ['full']: '100%',
  ['screen']: '100vw',
  ['min']: 'min-content',
  ['max']: 'max-content',
  ['fit']: 'fit-content',
  ['auto']: 'auto',
};

export const spaceTheme = { ...defaultSpacingStyle, ...generateRemObject(MAX_TOKEN_SIZE) } as const;
